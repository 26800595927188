import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'; // Assuming you have a CSS file for styling


function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Slider {...settings}>
    <div>
        <div className='coming_soon_banner'>
            Coming Soon!
        </div>
    </div>
    <div>
        <div className='image_text_container'>
            <div>
                <img src="human_vs_ai.jpg" alt="" className="logo_agent1" ></img>
            </div>
            <div>
                <h2 className="h_agent1">Agent 001</h2>
                <p className="p_agent1">An AI agent that is able to differentiate between human and AI generated text.</p>
            </div>        
        </div>
    </div>
    <div>
        <div className='image_text_container'>
            <div>
                <img src="x_ray.jpg" alt="" className="logo_agent2" ></img>
            </div>
            <div>
                <h2 className="h_agent2">Agent 002</h2>
                <p className="p_agent2">An AI agent that is able to differentiate between human and AI generated images. A human brain can be probed by measuring brainwaves. We measure the synapses of AI models and reveal interesting patterns!</p>
            </div>        
        </div>
    </div>
    <div>
        <div className='image_text_container'>
            <div>
                <img src="jokes.jpg" alt="" className="logo_agent3" ></img>
            </div>
            <div>
                <h2 className="h_agent3">Agent 003</h2>
                <p className="p_agent3">An AI agent showing its comedic side! Is it able to deliver the perfect punchline? ... BAZINGA!</p>
            </div>        
        </div>
    </div>
    </Slider>
  );
}

export default Carousel;
