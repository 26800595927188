import React from 'react';
import './App.css'; // Assuming you have a CSS file for styling
import './Carousel.js'; // Assuming you have a CSS file for styling
import Carousel from './Carousel.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="inferinsics.png" alt="" className="logo"/>
        <nav className="menu">
          <button className="menu-button">Home</button>
          {/* <button className="menu-button">About</button>
          <button className="menu-button">Services</button>
          <button className="menu-button">Contact</button> */}
        </nav>
      </header>
      <main>
        {
        <Carousel></Carousel>
        }
      </main>
    </div>
  );
}

export default App;
